<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <form data-vv-scope="template">
            <div class="row">
              <!--  Name -->
              <div class="col-md-12 mt-4">
                <label>{{$t('mails.template.name')}}</label>
                <custom-input
                  v-model="template.name"
                  name="name"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('template.name')">
                </custom-input>
              </div>
                <!--  Subject -->
                <div class="col-md-12 mt-4">
                  <label>{{$t('mails.template.subject')}}</label>
                  <div class="md-form mb-0">
                    <vue-tribute :options="titleVariableOptions">
                      <input
                        v-model="template.subject"
                        name="subject"
                        type="text"
                        class="md-form-control"
                        :class="[{ 'royalc-error-input': errors.first('template.subject') }]"
                        v-validate="{ required: true }"
                        :error="errors.first('template.subject')"
                      />
                      <transition name="slide" mode="">
                        <small v-if="errors.first('template.subject')" class="royalc-error-field">{{ errors.first('template.subject') }}</small>
                      </transition>
                    </vue-tribute>
                  </div>
                </div>

                <!-- Exibir Assinatura -->
                <div class="col-md-6 mt-4">
                  <label>{{$t('mails.template.show_signature')}}</label>
                  <custom-check-radio
                    :listItems="[{text: 'Ativo', value: 1}]"
                    v-model="template.showSignature"
                    name="showSignature"
                    type="checkbox"
                    inputClassName="p-switch p-fill mt-4"
                    stateClassName="p-primary"
                    v-validate="{ required: false }"
                    :error="errors.first('template.showSignature')">
                  </custom-check-radio>
                </div>
                <!-- Mail Content -->
                <div class="col-md-12 mt-4">
                  <div class="row">
                    <div class="col-md-8">
                      <label class="label-select">{{$t('mails.template.content_html')}} <i :title="'Digite {{Variável}} para adicionar variáveis ao template.'" class="fa fa-question-circle"></i></label>
                    </div>
  <!--                  <div class="col-md-4 text-right">-->
  <!--                      <button class="btn btn-primary btn-sm"><i class="fa fa-eye"></i>&nbsp;&nbsp;Prévia</button>-->
  <!--                  </div>-->
                    <div class="col-md-12">
                      <ckeditor name="content_html" @ready="customHandler" @focus="customHandler" @blur="customHandler" @input="customHandler" v-model="template.content_html" :config="editorConfig" v-validate="{ required: true }" ></ckeditor>
                      <transition name="slide" mode="">
                        <small v-if="errors.first('template.content_html')" class="royalc-error-field">{{ errors.first('template.content_html') }}</small>
                      </transition>
                    </div>
                  </div>
                </div>
                <!--  Attach -->
                <div class="col-md-12 mt-4">
                  <label>{{$t('mails.template.attach')}}</label>
                  <custom-file-upload
                    v-model="template.files"
                    name="attach"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: false }"
                    :error="errors.first('template.attach')">
                  </custom-file-upload>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!--    Actions Buttons  -->
      <div class="col-12 col-sm-12">
        <div class="row pull-right">
          <div class="col-12">
            <button type="button" class="btn  btn-outline-light  pull-right" @click="onSave">
              Salvar
            </button>
            <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
              Cancelar
            </button>
          </div>
        </div>
      </div>
      <!--    Actions Buttons  -->
    </div>
  </div>
</template>

<script>
import MailService from '@/services/MailService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'
import CustomCheckRadio from '@/components/Forms/CustomCheckRadio.vue'

// Mentions
import VueTribute from 'vue-tribute'
import 'tributejs/dist/tribute.css'

import Vue from 'vue'
import CKEditor from 'ckeditor4-vue'

Vue.use(CKEditor)

export default {
  name: 'mailForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s'
    }
  },
  data () {
    return {
      templateVarList: [],
      editorData: null,
      contactList: [],
      editorConfig: {
        // Cores disponíveis
        fontSize_sizes: '14/1.1em;16/1.2em;20/1.5em;',
        colorButton_colors: '7f683b,333,555,CCC,DDD,FFF',
        filebrowserUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Images',
        fileTools_requestHeaders: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        },
        height: 450,
        format_tags: 'p;h1;h2;h3;pre',
        format_h1: { element: 'h1', styles: { 'font-size': '20px', 'line-height': '30px', 'font-weight': 'bold', 'margin-top': '20px', 'margin-bottom': '20px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' }, attributes: { 'class': 'title' } },
        format_p: { element: 'p', styles: { 'font-size': '16px', 'line-height': '24px', 'margin-top': '16px', 'margin-bottom': '16px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' } },
        extraPlugins: ['mentions', 'uploadimage', 'colorbutton', 'font'],
        stylesSet: 'my_styles:/static/js/styles.js',
        fontFamily: {
          options: [
            'Helvetica,Sans-Serif'
          ]
        },
        toolbarGroups: [
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
          { name: 'links', groups: [ 'links' ] },
          { name: 'insert', groups: [ 'insert' ] },
          { name: 'styles', groups: [ 'styles' ] },
          { name: 'colors', groups: [ 'colors' ] },
          { name: 'tools', groups: [ 'tools' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          { name: 'about', groups: [ 'about' ] }
        ],
        removeButtons: 'Font,Maximize,Anchor,About,Scayt,NewPage,Save,Preview,Print,Templates,Cut,Copy,Paste,PasteText,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiRtl,BidiLtr,Language,Flash,Smiley,SpecialChar,Iframe,About',
        mentions: [
          {
            feed: this.variableList,
            marker: '{{',
            minChars: 0,
            itemTemplate: '<li data-id="{id}">' +
                '{label}' +
              '</li>',
            outputTemplate: '{name} | raw}}'
          }
        ]
      },
      isLoading: false,
      fullPage: false,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task',
      idiom: [],
      type: [],
      showCH: false,
      showCT: false,
      template: {
        name: null,
        subject: null,
        files: [],
        content_html: '<p style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px;margin-top:16px;"></p>',
        showSignature: 1
      }
    }
  },
  components: {
    Loading,
    CustomInput,
    CustomFileUpload,
    CustomCheckRadio,
    /* eslint-disable-next-line */
    MailService,
    VueTribute
  },
  created () {
    let _this = this

    let templateId = this.$route.params.id_template

    MailService.getContacts({}, 1, 1000).then(res => {
      this.contactList = res.data.data
      MailService.getVariables({}, 1, 1000).then(res => {
        this.templateVarList = []

        for (let index in res.data.data) {
          let item = res.data.data[index]
          if (item.codigo === 'nulo' || item.codigo === 'hoje' || item.codigo === 'dia_mes') {
            continue
          }

          this.templateVarList.push({
            id: item.id,
            name: item.nome,
            label: _this.applyTranslate(item.nome)
          })
        }

        // Ordenando campos por ordem alfabética considerando a tradução (Multi idioma)
        this.templateVarList.sort(function (a, b) {
          let keyA = a.label
          let keyB = b.label

          // Compare the 2 values
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })
      })
    })

    MailService.getMailTypes().then(response => {
      _this.type = response.data.data

      if (templateId) {
        MailService.getOneTemplate(templateId).then(response => {
          _this.template.name = response.data.data.name
          _this.template.content_html = response.data.data.content_html
          _this.template.description = response.data.data.description
          _this.template.idiom = response.data.data.idiom
          _this.template.subject = response.data.data.subject
          _this.template.type = response.data.data.type
          _this.template.showSignature = response.data.data.show_signature
          _this.template.files = response.data.data.files

          _this.type.map(type => {
            if (type.id === _this.template.type) {
              _this.template.type = type
            }
          })
        })
      }
    })
  },
  computed: {
    titleVariableOptions () {
      return {
        trigger: '{{',
        values: this.templateVarList,
        selectTemplate: function (item) {
          return '{{' + item.original.name + ' | raw }}'
        },
        lookup: 'label',
        fillAttr: 'name'
      }
    }
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'MailTemplate' })
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    variableList (opts, callback) {
      let query = opts.query

      // Simple search.
      // Filter the entire items array so only the items that start
      // with the query remain.
      let suggestions = this.templateVarList.filter(function (item) {
        return String(item.label).toLowerCase().indexOf(query.toLocaleLowerCase()) === 0
        // return String(item.label).indexOf(query) === 0
      })

      callback(suggestions)
    },
    customHandler (event) {
    },
    onSelectType (val) {
      this.template.type = val
    },
    onSave () {
      let _this = this
      let templateId = this.$route.params.id_template

      _this.isLoading = true
      _this.$validator.validateAll('template').then((result) => {
        if (result) {
          if (templateId) {
            MailService.editTemplate(templateId, this.template).then(response => {
              _this.$router.push({ name: 'MailTemplate' })
            }).catch(() => {
              _this.isLoading = false
            })
          } else {
            MailService.newTemplate(this.template).then(response => {
              _this.$router.push({ name: 'MailTemplate' })
            }).catch(() => {
              _this.isLoading = false
            })
          }
        } else {
          _this.isLoading = false
        }
      })
    },
    customLabel ({ email }) {
      // eslint-disable-next-line camelcase
      return `${email}`
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

</style>
